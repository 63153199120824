import React from 'react';
import logo from '../assets/logo.svg'; // Path to your SVG logo

const Loader = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-blue-500 relative">
        {/* Logo positioned at the center of the spinner */}
        <img src={logo} className="h-12 w-12 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" alt="Logo" />
      </div>
    </div>
  );
};

export default Loader;
