
import './App.css';
import React, { useState, useEffect } from 'react';
import Loader from './components/Loader';
import { UnderMaintenance } from './components/UnderMaintenance';

function App() {
  const maintenanceGoing = true;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating async operation (e.g., fetching data)
    setTimeout(() => {
      setLoading(false); // Set loading to false after some time
    }, 1000); // Simulate 3 seconds loading time
  }, []);

  return (
    <div className="App">
      {
        maintenanceGoing 
          ? ( <UnderMaintenance/> ) 
          : ( <> 
                {loading ? (
                  <Loader />
                ) : (
                  <div className="container mx-auto p-4">
                   
                  </div>
                )}
              </> 
            )
      }
     
    </div>
  );
}

export default App;
