
export const UnderMaintenance = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="mb-4">
        <div className="animate-spin rounded-full h-20 w-20 border-t-2 border-b-2 border-blue-500"></div>
      </div>
      <h1 className="text-2xl font-bold">Under maintenance</h1>
    </div>
  );
};
